
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import client from './client';
import { API_ENDPOINTS } from './client/api-endpoints';
import { mapPaginatorData } from 'framework/rest/utils/data-mappers';
import { formatProductsArgs } from 'framework/rest/utils/format-products-args';
// import { useTranslation } from 'next-i18next';
// import { useModalAction } from '@/components/ui/modal/modal.context';
// import { toast } from 'react-toastify';
// import { useRouter } from 'next/router';

export function useProducts(options) {
  // const { locale } = useRouter();

  const formattedOptions = {
    ...formatProductsArgs(options),
    language: 'en',
  };

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [API_ENDPOINTS.PRODUCTS, formattedOptions],
    ({ queryKey, pageParam }) =>
      client.products.all(Object.assign({}, queryKey[1], pageParam)),
    {
      getNextPageParam: ({ current_page, lastPage }) =>
        lastPage > current_page && { page: current_page + 1 },
    }
  );


  function handleLoadMore() {
    fetchNextPage();
  }

  console.log("map", mapPaginatorData(data?.pages[data.pages.length - 1]))

  return {
    products: data?.pages?.flatMap((page) => page.data) ?? [],
    paginatorInfo: Array.isArray(data?.pages)
      ? mapPaginatorData(data?.pages[data.pages.length - 1])
      : null,
    isLoading,
    error,
    isFetching,
    isLoadingMore: isFetchingNextPage,
    loadMore: handleLoadMore,
    hasMore: Boolean(hasNextPage),
  };
}


export function useProduct({ slug }) {
  // const { locale: language } = useRouter();

  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.PRODUCTS, { slug }],
    () => client.products.get({ slug })
  );
  return {
    product: data,
    isLoading,
    error,
  };
}
