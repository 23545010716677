// import { useModalAction } from '@/components/ui/modal/modal.context';
import { useTranslation } from 'react-i18next';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import client from './client';
// import { authorizationAtom } from '@/store/authorization-atom';
// import { useAtom } from 'jotai';
// import { signOut as socialLoginSignOut } from 'next-auth/react';
// import { useToken } from '@/lib/hooks/use-token';
// import { API_ENDPOINTS } from './client/api-endpoints';
// import { useState } from 'react';
// import type {
//   RegisterUserInput,
//   ChangePasswordUserInput,
//   OtpLoginInputType,
// } from '@/types';
// import { initialOtpState, optAtom } from '@/components/otp/atom';
// import { useStateMachine } from 'little-state-machine';
// import {
//   initialState,
//   updateFormState,
// } from '@/components/auth/forgot-password';
// import { clearCheckoutAtom } from '@/store/checkout';


export const useContact = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return useMutation(client.users.contactUs, {
    onSuccess: (data) => {
      if (data) {

        toast.success('Thank you for contacting us. We will get back you soon.');
        navigate('/')
      }

      // if (data.success) {
      //   toast.success(t(data.message));
      // } else {
      //   toast.error(t(data.message));
      // }
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

