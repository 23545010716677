// import { useRouter } from 'next/router';
import cn from 'classnames';
import React from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getIcon } from './../../lib/get-icon';
// import { getIcon } from '@/lib/get-icon';
import * as CategoryIcons from '../icons-2';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const CategoryItem = ({ item }) => {
    const navigate = useNavigate();
    let query = useQuery();

    const selectedQueries = query.get("category")

    const onCategoryClick = (slug) => {
        if (selectedQueries === slug) {
            navigate({
                pathname: "/",
                search: createSearchParams({

                }).toString()
            });
            return
        }
        navigate({
            pathname: "/",
            search: createSearchParams({
                category: slug
            }).toString()
        });
    };

    return (
        <div
            className={cn(
                'text-center rounded bg-light py-4 flex flex-col items-center justify-start relative overflow-hidden cursor-pointer border-2',
                selectedQueries === item?.title
                    ? 'border-gray-800'
                    : 'border-border-100 xl:border-transparent'
            )}
            role="button"
            onClick={() => onCategoryClick(item?.title)}
        >
            <div className="w-full h-20 flex items-center justify-center">
                <span className="w-20 h-20 inline-block">
                    {/* {getIcon({
                        iconList: CategoryIcons,
                        iconName: item.icon,
                        className: 'w-10 h-10',
                    })} */}
                    <img
                        src={item?.images[0]}
                        alt={'df'}
                        layout="fill"
                        // objectFit="contain"
                        className="product-image"
                    />
                </span>
            </div>

            <span className="text-sm font-semibold text-heading text-center px-2.5 block">
                {item?.title}
            </span>
        </div>
    );
};

function OutlinedBoxedCategoryMenu({ items }) {
    return (
        <>
            {items?.map((item, key) => (
                <CategoryItem key={`${item.title}${item.title}`} item={item} />
            ))}
        </>
    );
}

export default OutlinedBoxedCategoryMenu;
