import React from 'react'
import Categories from '../categories/categories'
import ProductGridHome from '../products/grid/home'
import { Element } from 'react-scroll';
export default function Classic() {
    return (
        <Element
            name="grid"
            className="flex border-t border-solid border-border-200 border-opacity-70"
        >
            <Categories />
            <ProductGridHome className="px-4 pb-8 lg:p-8" />
        </Element>
    )
}
