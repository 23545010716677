
import { API_ENDPOINTS } from './api-endpoints';
import { HttpClient } from './http-client';

class Client {
  products = {
    all: ({
      type,
      categories,
      name,
      shop_id,
      author,
      manufacturer,
      min_price,
      max_price,
      tags,
      ...params
    }) =>
      HttpClient.get(API_ENDPOINTS.PRODUCTS, {
        // searchJoin: 'and',
        // with: 'type;author',
        ...params,
        // search: HttpClient.formatSearchParams({
        //   categories,
        //   name,
        //   shop_id,
        //   author,
        //   manufacturer,
        //   min_price,
        //   max_price,
        //   tags,
        //   status: 'publish',
        // }
        // ),
      }),


    get: ({ slug }) =>
      HttpClient.get(`${API_ENDPOINTS.PRODUCTS}/${slug}`, {
        // searchJoin: 'and',
        // with: 'categories;shop;type;variations;variations.attribute.values;manufacturer;variation_options;tags;author',
      }),


  };

  categories = {
    all: ({ type, ...params }) =>
      HttpClient.get(API_ENDPOINTS.CATEGORIES, {
        searchJoin: 'and',
        ...params,
        ...(type && { search: HttpClient.formatSearchParams({ type }) }),
      }),
  };

  users = {
    contactUs: (input) =>
      HttpClient.post(API_ENDPOINTS.SEND_EMAIL, input)
  }

  checkout = {
    sentOrder: (input) =>
      HttpClient.post(API_ENDPOINTS.SEND_ORDER, input)
  }



}

export default new Client();
