import React from 'react'
import cn from 'classnames';

export default function Logo({
    className,
    ...props
}) {
    return (
        <div>
            <a href="/" className={cn('inline-flex', className)} {...props}>
                <span className="relative  w-[5rem] overflow-hidden md:w-[6rem]">
                    <img
                        // src={'https://pickbazar-react.vercel.app/_next/image?url=https%3A%2F%2Fpickbazarlaravel.s3.ap-southeast-1.amazonaws.com%2F860%2FPickBazar.png&w=3840&q=75'}
                        src={'logo.png'}
                        alt={'PickBazar Logo'}
                        // layout="fill"
                        // objectfit="contain"
                        loading="eager"
                    />
                </span>
            </a>
        </div>
    )
}
