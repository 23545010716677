import React from 'react'
import NotFound from '../../pages/not-found'
import Scrollbar from '../ui/scrollbar'
import OutlinedBoxedCategoryMenu from './outlined-boxed-category'

export default function StickySidebarBoxedCategories({
    categories,
    notFound,
    className
}) {
    return (
        <aside
            className={`hidden h-full w-full bg-light lg:sticky lg:top-22 lg:w-[380px] lg:bg-gray-100 xl:block ${className}`}
        >
            <Scrollbar style={{ maxHeight: 'calc(100vh - 88px)' }}>
                <div className="p-5">
                    {!notFound ? (
                        <div className="grid grid-cols-2 gap-4">

                            <OutlinedBoxedCategoryMenu items={categories} className="py-8" />
                        </div>
                    ) : (
                        <div className="min-h-full px-4 pt-6 pb-8 lg:p-8">
                            <NotFound text="text-no-category" className="h-96" />
                        </div>
                    )}
                </div>
            </Scrollbar>
        </aside>
    )
}
