import Counter from 'components/ui/counter';
import AddToCartBtn from 'components/products/add-to-cart/add-to-cart-btn';
import { cartAnimation } from 'lib/cart-animation';
import { useCart } from 'store/quick-cart/cart.context';
import { generateCartItem } from 'store/quick-cart/generate-cart-item';


export const AddToCart = ({
  data,
  variant = 'helium',
  counterVariant,
  counterClass,
  variation,
  disabled,
}) => {
  const {
    addItemToCart,
    addItemToCartMy,
    removeItemFromCart,
    isInStock,
    getItemFromCart,
    isInCart,
    updateCartLanguage,
    language
  } = useCart();
  // const item = generateCartItem(data, variation);
  const item = data


  const handleAddClick = (
    e
  ) => {
    e.stopPropagation();
    // Check language and update
    // if (item?.language !== language) {
    //   updateCartLanguage(item?.language);
    // }

    console.log('card item :>> ', item);
    addItemToCartMy(item);
    if (!isInCart(item.id)) {
      cartAnimation(e);
    }
  };
  const handleRemoveClick = (e) => {
    e.stopPropagation();
    removeItemFromCart(item.id);
  };
  const outOfStock = isInCart(item?.id) && !isInStock(item.id);
  return !isInCart(item?.id) ? (
    <AddToCartBtn
      disabled={disabled || outOfStock}
      variant={'big'}
      onClick={handleAddClick}
    />
  ) : (
    <>
      <AddToCartBtn
        // disabled={disabled || outOfStock}
        variant={'big'}
        onClick={handleAddClick}
      />
      {/* <Counter
        value={getItemFromCart(item.id).quantity}
        onDecrement={handleRemoveClick}
        onIncrement={handleAddClick}
        variant={counterVariant || variant}
        className={counterClass}
        disabled={outOfStock}
      /> */}
    </>
  );
};
