import camelCaseKeys from 'camelcase-keys';

export const mapPaginatorData = (obj) => {


  if (!obj) return null;
  const { data, ...formattedValues } = camelCaseKeys(obj);





  return {
    ...formattedValues,
    hasMorePages: formattedValues.lastPage !== formattedValues.currentPage,

    // firstItem: formattedValues.from,
    // lastItem: formattedValues.to,
  };
};
