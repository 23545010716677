// import { AUTH_TOKEN_KEY } from '@/lib/constants';
// import type { SearchParamOptions } from '@/types';
import axios from 'axios';
import Cookies from 'js-cookie';
// import Router from 'next/router';

const AUTH_TOKEN_KEY = "auth_token"
const Axios = axios.create({
  baseURL: process.env.REACT_APP_REST_API_ENDPOINT,
  timeout: 5000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
Axios.interceptors.request.use((config) => {
  const token = Cookies.get(AUTH_TOKEN_KEY);
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token ? token : ''}`,
  };
  return config;
});

// Change response data/error here
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response &&
        error.response.data.message === 'PICKBAZAR_ERROR.NOT_AUTHORIZED')
    ) {
      Cookies.remove(AUTH_TOKEN_KEY);
      // Router.reload();
    }
    return Promise.reject(error);
  }
);


export class HttpClient {

  static async get(url, params) {

    // console.log('https://twps.ch/api' + url, { params })
    const response = await Axios.get('https://twps.ch/api' + url, { params });
    return response.data;
  }
  // static async get(url, params) {

  //   // const response = await Axios.get('http://localhost:5000/api/products?limit=30');
  //   const response = await Axios.get('https://twps.ch/api' + url);

  //   return response.data;
  // }

  static async post(url, data, options) {
    const response = await Axios.post(`https://twps.ch/api${url}`, data, options);
    return response.data;
  }

  static async put(url, data) {
    const response = await Axios.put(url, data);
    return response.data;
  }

  static async delete(url) {
    const response = await Axios.delete(url);
    return response.data;
  }

  static formatSearchParams(params) {
    return Object.entries(params)
      .filter(([, value]) => Boolean(value))
      .map(([k, v]) =>
        ['type', 'categories', 'tags', 'author', 'manufacturer'].includes(k)
          ? `${k}.slug:${v}`
          : `${k}:${v}`
      )
      .join(';');
  }
}
