import { useTranslation } from 'react-i18next';
// import { siteSettings } from '@/config/site';
import Logo from 'components/ui/logo';
// import SubscriptionWidget from 'components/settings/subscribe-to-newsletter';
const footerPaymentMethods = [
  {
    img: '/payment/master.png',
    url: '/',
  },
  {
    img: '/payment/skrill.png',
    url: '/',
  },
  {
    img: '/payment/paypal.png',
    url: '/',
  },
  {
    img: '/payment/visa.png',
    url: '/',
  },
  {
    img: '/payment/discover.png',
    url: '/',
  },
]
const Footer = () => {
  const footerMenus = [
    {
      title: 'text-explore',
      links: [
        {
          name: 'text-about-us',
          href: '/',
        },
        {
          name: 'text-sitemap',
          href: '/',
        },
        {
          name: 'text-bookmarks',
          href: '/',
        },
        {
          name: 'text-sign-join',
          href: '/',
        },
      ],
    },
    {
      title: 'text-customer-service',
      links: [
        {
          name: 'Help',
          href: 'help',
        },
        {
          name: 'text-returns',
          href: '/',
        },
        {
          name: 'text-accessibility',
          href: '/',
        },
        {
          name: 'text-contact-us',
          href: 'contact',
        },
        {
          name: 'text-store-pickup',
          href: '/',
        },
      ],
    },
    {
      title: 'text-our-information',
      links: [
        {
          name: 'text-privacy-update',
          href: 'privacy',
        },
        {
          name: 'text-terms-condition',
          href: 'terms',
        },
        {
          name: 'text-return-policy',
          href: '/',
        },
        {
          name: 'text-sitemap',
          href: '/',
        },
      ],
    },
  ]
  const { t } = useTranslation('common');
  return (
    <div className="flex w-full flex-col border-gray-800 bg-white px-5 md:px-10 lg:border-b-8 lg:px-[50px] xl:px-16">
      {/* Top */}
      <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 pt-16 md:grid-cols-3 lg:pt-24 lg:pb-16 xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] xl:gap-8 2xl:grid-cols-5">
        <div className="flex flex-col">
          <div className="mb-[2px] flex h-16 items-start">
            {/* <Logo /> */}
          </div>

          <address className="mb-7 text-sm not-italic text-heading">
            8253 Diessenhofen <br />
            Ratihard 4<br />
            Switzerland.
          </address>
          <span className="mb-1 text-sm text-heading">
            info@twps.ch
          </span>
          <span className="text-sm text-heading">
            +41 78 891 09 44
          </span>
        </div>

        {footerMenus.map((menu, idx) => (
          <div className="flex flex-col" key={`${menu.title}-${idx}`}>
            <h3 className="mt-3 mb-4 font-semibold text-heading lg:mb-7">
              {t(menu.title)}
            </h3>

            <ul className="space-y-3">
              {menu.links.map((link, index) => (
                <li key={`${link.href}-${index}`}>
                  <a
                    href={link.href}
                    className="text-sm text-heading transition-colors hover:text-orange-500"
                  >
                    {t(link.name)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        {/* <div className="col-span-full md:col-span-2 lg:col-auto">
          <SubscriptionWidget
            title="text-subscribe-now"
            description="text-subscribe-details"
          />
        </div> */}
      </div>

      {/* Bottom */}
      <div className="mt-8 flex w-full flex-col items-center border-t border-gray-200 pt-8 pb-12 lg:mt-0 lg:flex-row lg:justify-between lg:border-t-0">
        <span className="order-2 text-sm text-heading lg:order-1">
          &copy; {t('text-copyright')} {new Date().getFullYear()}{' '}
          <a
            className="font-bold text-heading transition-colors hover:text-accent"
            href={'#'}
          >
            TWPS
          </a>{' '}
          {t('text-rights-reserved')}
        </span>

        {footerPaymentMethods && (
          <div className="order-1 mb-5 flex items-center space-x-5 rtl:space-x-reverse lg:order-2 lg:mb-0">
            {footerPaymentMethods.map((method, idx) => (
              <a
                className="relative flex h-5 w-auto items-center overflow-hidden"
                key={`${method.url}-${idx}`}
                href={method.url}
              >
                {/* eslint-disable */}
                <img src={method.img} className="max-h-full max-w-full" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
