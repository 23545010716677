export const privacyPolicy = {
  title: 'Allgemeine Geschäftsbedingung (AGB) von TWPS',
  date: '',
  content: [
    {
      id: '1',
      title: 'Allgemeines',
      description: 'Die nachstehenden Bedingung gelten in der jeweils aktuellen Fassung für alle künftigen Verträge mit TWPS (Im Glockengut 9B, 8207 Schaffhausen) Handelsregister…..) ausschliesslich. Zusatzvereinbarungen werden nur schriftlich getroffen',
    },
    {
      id: '2',
      title: 'Änderungen',
      description: 'Wir behalten uns das Recht vor zumutbare Änderungen der Produkte ohne Vorankündigung durchzuführen, sofern dadurch der Vetragsgegenstand keine unzumutbare Änderung erfährt. Unsere Angaben über Eigenschaften und Verwendungszwecke der Produkte dienen der blossen Beschreibung und enthalten keine aktuelle Eigenschaftszusicherung. <br /> <br /> Produktabbildungen sind nur als Muster zu betrachten. Nur zugesandte Produktemuster gelten als Anschauungsstücke für Qualität, Material und Eigenschaften. Irrtümer sind durch fehlerhafte Informationsübermittlung der Lieferanten oder Hersteller möglich. Die Eigenschaften von Produkten werden erst und ausschliesslich durch die angeforderte Übersendung der auf die Artikel bezogenen aktuellen Produktdatenblätter oder Zertifikate des Herstellers oder Inverkehrbringers zugesichert.',
    },
    {
      id: '3',
      title: 'Preise, Versand- und Frachtkosten und Lizenzentgelte',
      description: 'Unsere Angebote richten sich an Gastronomie, Takeway, Handel Industrie. Alle angegebenen Preise sind, wenn nicht anders ausgewiesen, immer Nettopreise in CHF zuzüglich der gültigen Mehrwertsteuer und Versand- oder Frachtkosten. Die Preise enthalten keine eventuell anfallenden Entsorgungskosten oder Lizenzentgelte des Dualen Systems für Verpackungen.',
    },
    {
      id: '4',
      title: 'Vertragsabschluss',
      description: 'Ein Vertrag kommt erst mit schriftlicher Auftragsbestätigung oder Versand der bestellten Ware an die zuletzt bekannte Adresse zustande. Wir behalten uns das Recht vor, noch offenstehende Lieferungen zu stoppen bzw. Vorkasse zu verlangen, wenn zwischen Vertragsabschluss und Leistungserbringung begründete Zweifel an der Bonität des Käufers auftreten oder Zahlungsverzug fälliger Rechnungen eintritt.',
    },
    {
      id: '5',
      title: 'COMPLAINTS',
      description: 'If you believe your privacy has been breached or you have a complaint about how we have handled your personal information, please contact us in writing. We will respond within a reasonable period (usually within 30 days).',
    },
    {
      id: '6',
      title: 'Lieferung, Bezahlung und Eigentumsvorbehalt',
      description: 'Als Bestandteil der AGB gelten unsere aufgelisteten Versand- und Zahlungsbedingungen auf unseren Webshopseiten. Ferner gilt: alle Lieferzeitangaben sind unverbindlich. Bei Erstbestellungen kann sich die Auslieferung aufgrund Vorkasse oder durchzuführender Bonitätsprüfungen verlängern. <br /> Bonitätsprüfung: Zum Zwecke der Kreditprüfung wird uns (die Creditreform) Ihre Homepage zur Verfügung stellen, sofern wir unser berechtigtes Interesse glaubhaft dargelegt haben.Wir sind zu zumutbaren Teillieferungen ohne Mehrkosten für den Käufer berechtigt. <br /> Transportschäden müssen unverzüglich beim Frachtführer gerügt werden. In diesem Fall ist der Kunde verpflichtet, den Ablieferer (Mitarbeiter) auf die Transportschäden hinzuweisen und die Annahme ist zu verweigern. Die Lieferung der Ware erfolgt - wenn nichts anderes vereinbart - gegen Vorkasse. <br /> Bei Abschluss einer Lastschriftvereinbarung erfolgt die Lieferung gegen Rechnung mit Gewährung eines Zahlungsziels. Gesondert kann eine Lieferung gegen Rechnung vereinbart werden. Die Aufrechnung oder Zurückbehaltung von Zahlungen wegen von uns bestrittener und nicht rechtskräftig festgestellter Gegenansprüche ist nicht möglich. <br />   Bei mehrfacher Zahlungserinnerung erheben wir ab der zweiten Mahnstufe eine Aufwandspauschale von CHF 30.00, ab der dritten Mahnstufe von CHF 50.00. Die gelieferte Ware bleibt bis zur vollständigen Zahlung aller Forderungen aus dem Kaufvertrag unser Eigentum. Kommt der Käufer seinen vertraglichen Pflichten nicht nach, insbesondere im Fall des Zahlungsverzugs, sind wir berechtigt, vom Vertrag zurückzutreten und den gelieferten Gegenstand heraus zu verlangen; der Käufer ist zur Herausgabe des Gegenstandes verpflichtet auf Kosten des Käufers.',
    },
    {
      id: '7',
      title: 'Gewährleistung und Haftung',
      description: "Offensichtliche Mängel müssen im kaufmännischen Verkehr unverzüglich nach Erhalt der Ware, spätestens jedoch innert 7 Tagen, mitgeteilt werden. Verborgene Mängel sind unverzüglich nach ihrer Entdeckung schriftlich anzuzeigen. Unterlässt der Käufer die Rüge in frist- und formgerechter Anzeige, gilt die Ware als genehmigt. Unternehmern steht im Gegensatz zu Privatleuten kein gesetzlicher Anspruch auf Widerruf zu. Bei Fehlbestellung rufen Sie uns bitte an und wir klären die Kulanzmöglichkeiten im Einzelfall mit Ihnen. Unangemeldete Rückversand der Ware wird nicht von uns entgegengenommen.  Wir haften bei Verletzung einer Vertrags Pflicht höchstens auf den vorhersehbaren, vertragstypischen Schaden."
    },
    {
      id: '8',
      title: 'Datenschutz und Urheberrecht',
      description: 'Als Bestandteil der AGB gilt unsere ausgewiesene Datenschutzerklärung auf unseren Webshopseiten. <br /> Ferner gilt: Der Kunde ist damit einverstanden, dass seine im Rahmen der Geschäftsbeziehungen zugehenden personenbezogenen Daten zur Abwicklung des Geschäftsverhältnisses in unserer EDV-Anlage gespeichert und automatisch verarbeitet werden. Für diese Zwecke werden Ihre Daten auch an vor- oder nachgeordnete Service-Dienstleister, z. B. Frachtführer und Paketdienste, weitergegeben. <br /> Von uns zur Verfügung gestellte Verpackungsentwürfe, die für eine Angebots- oder Vertragsverhandlung von uns angefertigt werden, unterliegen dem Urheberschutzrecht und bleiben bis zum Vertragsabschluss unser Eigentum. Eine Vervielfältigung oder Weitergabe ist nur mit unserer schriftlichen Zustimmung erlaubt. Dies gilt auch für alle weiteren zur Verfügung gestellten Werbe- und Marketingmaterialien, unabhängig von Format, Ausführung oder Verwendungszweck.'
    },
    {
      id: '9',
      title: 'Rechtswirksamkeit, Recht und Gerichtsstand',
      description: 'Durch die Unwirksamkeit einzelner Bestimmungen dieser Allgemeinen Geschäftsbedingungen wird die Gültigkeit der übrigen Bestimmungen nicht berührt. Die Beziehung zwischen den Vertragsparteien regelt sich ausschliesslich nach dem in der Schweizer Verfassung geltenden Rechte. Erfüllungsort und ausschliesslicher Gerichtsstand ist Schaffhausen.'
    }
  ],
};
