export const faq = [
  {
    title: 'How to contact with Customer Service?',
    content: 'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!.',
  },
  {
    title: 'App installation failed, how to update system information?',
    content: 'Please read the documentation carefully . We also have some online video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum',
  },
  {
    title: 'Website response taking time, how to improve?',
    content: 'At first, Please check your internet connection . We also have some online video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum.',
  },
  {
    title: 'How do I create a account?',
    content: 'If you want to open an account for personal use you can do it over the phone or online. Opening an account online should only take a few minutes.',
  },
];
