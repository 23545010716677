// import type { CreateContactUsInput } from '@/types';
import Button from 'components/ui/button';
import { Form } from 'components/ui/form/form';
import Input from 'components/ui/form/input';
import TextArea from 'components/ui/form/text-area';
import { useContact } from 'framework/rest/user';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const contactFormSchema = yup.object().shape({
    name: yup.string().required('error-name-required'),
    from: yup
        .string()
        .email('error-email-format')
        .required('error-email-required'),
    subject: yup.string().required('error-subject-required'),
    message: yup.string().required('error-description-required'),
});


const ContactForm = () => {
    const { t } = useTranslation('common');
    const { mutate, isLoading } = useContact();

    function onSubmit(values) {
        mutate(values);
    }

    return (
        <Form
            onSubmit={onSubmit}
            validationSchema={contactFormSchema}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <Input
                            label={'Name'}
                            {...register('name')}
                            variant="outline"
                            error={t(errors.name?.message)}
                        />
                        <Input
                            label={'Email'}
                            {...register('from')}
                            type="email"
                            variant="outline"
                            error={t(errors.from?.message)}
                        />
                    </div>
                    <Input
                        label={'Subject'}
                        {...register('subject')}
                        variant="outline"
                        className="my-6"
                        error={t(errors.subject?.message)}
                    />
                    <TextArea
                        label={'Message'}
                        {...register('message')}
                        variant="outline"
                        className="my-6"
                        error={t(errors.message?.message)}
                    />

                    <Button loading={isLoading} disabled={isLoading}>
                        {t('text-submit')}
                    </Button>
                </>
            )}
        </Form>
    );
};

export default ContactForm;
