import Header from "./components/layout/header";
import StaticMenu from "./components/layout/menu/static-menu";
import NotFound from "./pages/not-found";
import { useTranslation } from 'react-i18next';
import Classic from "./components/layout/classic";
import BannerWithoutSlider from "./components/banners/banner-without-slider";
import {
  BrowserRouter as Router, Route, Routes,
} from "react-router-dom";
import { ModalProvider } from "./components/ui/modal/modal.context";
import ManagedModal from "./components/ui/modal/managed-modal";
import { useEffect } from "react";
import QueryProvider from "framework/rest/client/query-provider";
import HomeLayout from "components/layout/home-layout";
import BannerShort from "components/banners/banner-short";
import Footer from "components/layout/footer";
import Contact from "contact";
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import Home from "pages/home";
import GeneralLayout from "components/layout/general";
import NotFoundPage from "pages/404";
import ManagedDrawer from "components/ui/drawer/managed-drawer";
import HelpPage from "pages/help";
import PrivacyPage from "pages/privacy";
import TermsPage from "pages/terms";
import { CartProvider } from "store/quick-cart/cart.context";
import CartCounterButton from "components/cart/cart-counter-button";
import CheckoutPage from "pages/checkout";

export default function App() {
  const { t } = useTranslation('common');



  return (
    <QueryProvider>
      <ModalProvider>
        <CartProvider>


          <Router>
            <ManagedDrawer />
            <ManagedModal />
            <div className="flex flex-col min-h-screen transition-colors duration-150 bg-gray-100">

              <Routes>
                <Route path="/" element={<HomeLayout />}>
                  <Route index element={<Home />} />
                </Route>

                <Route path="/contact" element={<GeneralLayout />}>
                  <Route index element={<Contact />} />
                </Route>

                <Route path="/help" element={<GeneralLayout />}>
                  <Route index element={<HelpPage />} />
                </Route>

                <Route path="/privacy" element={<GeneralLayout />}>
                  <Route index element={<PrivacyPage />} />
                </Route>

                <Route path="/terms" element={<GeneralLayout />}>
                  <Route index element={<TermsPage />} />
                </Route>

                <Route path="/checkout" element={<GeneralLayout />}>
                  <Route index element={<CheckoutPage />} />
                </Route>

                {/* <Route path="/checkout" element={<CheckoutPage />} /> */}

                <Route path="*" element={<NotFoundPage />} />

              </Routes>
              <CartCounterButton />
            </div>
            <Footer />

          </Router>
        </CartProvider>
      </ModalProvider>
    </QueryProvider>

  );
}