import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import client from './client';

export const useCheckout = () => {
    const navigate = useNavigate();

    return useMutation(client.checkout.sentOrder, {
        onSuccess: (data) => {
            if (data) {
                toast.success('Thank you for your order. We will get back you soon.');
                navigate('/')
            }
        },
        onError: (err) => {
            console.log(err);
        },
    });
};