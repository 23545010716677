import BannerShort from 'components/banners/banner-short'
import BannerWithoutSlider from 'components/banners/banner-without-slider'
import CartCounterButton from 'components/cart/cart-counter-button'
import Classic from 'components/layout/classic'
import PromotionSliders from 'components/promotions/promotions'
import React from 'react'
import { ToastContainer } from 'react-toastify'

export default function Home() {
    return (
        <>


            <BannerShort banners={
                [
                    'images/web-01.png',
                    'images/website-02.png',
                    'images/website-03.png',
                ]} />

            <PromotionSliders />

            <div className="min-h-screen mt-5">
                <Classic />
            </div>

            {/* <BannerWithoutSlider slug="clothing" banners={
                [
                    {
                        "id": 16,
                        "type_id": 5,
                        "title": "We go the reusable",
                        "description": "Reusable concepts are increasingly in demand in the to-go market. In order to meet this need, we have put together a new B2B reusable range. This runs under the name repEAT – Multiple eats better!",
                        "image": {
                            "id": 911,
                            "original": "images/website-02.png",
                            "thumbnail": "https://www.teacoffeepackingmaterial.com/wp-content/uploads/2020/01/concept_01.png"
                        },
                        "created_at": "2021-07-17T13:24:28.000000Z",
                        "updated_at": "2021-07-17T13:24:28.000000Z"
                    }
                ]
            } /> */}

            <ToastContainer autoClose={2000} theme="colored" />

        </>
    )
}
