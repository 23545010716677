import { useProducts } from 'framework/rest/product';
import { Grid } from 'components/products/grid';
import React from 'react';
import { useLocation } from 'react-router-dom';
// import { useRouter } from 'next/router';

export default function ProductGridHome({
  className,
  variables,
  column,
  gridClassName,
}) {
  // const { query } = useRouter();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();


  const { products, loadMore, isLoadingMore, isLoading, hasMore, error } =
    useProducts({
      ...variables,
      ...(query.get("category") && { category: query.get("category") }),
      ...(query.get("searchString") && { searchString: query.get("searchString") }),
    });

  console.log('home', { products, loadMore, isLoadingMore, isLoading, hasMore, error })

  const productsItem = products;
  return (
    <Grid
      products={productsItem}
      loadMore={loadMore}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      hasMore={hasMore}
      error={error}
      limit={20}
      className={className}
      gridClassName={gridClassName}
      column={column}
    />
  );
}
