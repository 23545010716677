// import ContactForm from '@/components/settings/contact-form';
// import { Image } from '@/components/ui/image';
// import contactIllustration from '@/assets/contact-illustration.svg';
// import { getLayout } from '@/components/layouts/layout';
// import { formatAddress } from '@/lib/format-address';
// import { getIcon } from '@/lib/get-icon';
// import isEmpty from 'lodash/isEmpty';
import * as socialIcons from 'components/icons/social';
// import Seo from '@/components/seo/seo';
// import { useSettings } from '@/framework/settings';
// export { getStaticProps } from '@/framework/general.ssr';

import { getIcon } from "lib/get-icon";
import ContactForm from "./components/settings/contact-form";

const contactDetails = [
    {
        "url": "https://www.facebook.com/",
        "icon": "FacebookIcon"
    },
    {
        "url": "https://twitter.com/home",
        "icon": "TwitterIcon"
    },
    {
        "url": "https://www.instagram.com/",
        "icon": "InstagramIcon"
    }
]

export const Contact = () => {
    return (
        <>
            {/* <Seo title={'Contact'} url={'contact'} /> */}
            <div className="w-full bg-gray-100">
                <div className="mx-auto flex w-full max-w-7xl flex-col py-10 px-5 md:flex-row xl:py-14 xl:px-8 2xl:px-14">
                    {/* sidebar */}
                    <div className="order-2 w-full shrink-0 bg-light p-5 md:order-1 md:w-72 lg:w-96">
                        <div className="mb-8 flex w-full items-center justify-center overflow-hidden">
                            <img
                                className="h-auto w-full"
                                alt={"nav-menu-contact"}
                                src="https://pickbazar-react.vercel.app/_next/static/media/contact-illustration.2f6adc05.svg" />
                            {/* <Image
                                src={contactIllustration}
                                alt={t('nav-menu-contact')}
                                className="h-auto w-full"
                            /> */}
                        </div>

                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                Open
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-body">
                                    Monday - Friday
                                </span>
                                <p
                                    rel="noreferrer"
                                    className="text-sm font-semibold text-accent hover:text-accent-hover focus:text-blue-500 focus:outline-none"
                                >
                                    08.30 - 17.00
                                </p>
                            </div>

                            <div className="flex items-center justify-between">
                                <span className="text-sm text-body">
                                    Saturday
                                </span>
                                <p
                                    rel="noreferrer"
                                    className="text-sm font-semibold text-accent hover:text-accent-hover focus:text-blue-500 focus:outline-none"
                                >
                                    08.30 - 12.00
                                </p>
                            </div>

                            <div className="flex items-center justify-between">
                                <span className="text-sm text-body">
                                    Sunday
                                </span>
                                <p
                                    rel="noreferrer"
                                    className="text-sm font-semibold text-accent hover:text-accent-hover focus:text-blue-500 focus:outline-none"
                                >
                                    Closed
                                </p>
                            </div>
                        </div>

                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                Address
                            </span>
                            <span className="text-sm text-body">
                                Ratihard 4 <br /> 8253 Diessenhofen <br /> Schweiz
                                {/* {!isEmpty(formatAddress(settings?.contactDetails?.location))
                                    ? formatAddress(settings?.contactDetails?.location)
                                    : t('common:text-no-address')} */}


                            </span>
                        </div>

                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                Phone
                            </span>
                            <span className="text-sm text-body">
                                +41788910944
                            </span>
                        </div>

                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                Email
                            </span>
                            <span className="text-sm text-body">
                                info@twps.ch
                            </span>
                        </div>




                        {/* {settings?.contactDetails?.website && ( */}
                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                Website
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-body">
                                    http://twps.ch
                                </span>
                                <a
                                    href={'http://twps.ch'}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-sm font-semibold text-accent hover:text-accent-hover focus:text-blue-500 focus:outline-none"
                                >
                                    Visit
                                </a>
                            </div>
                        </div>


                        <div className="mb-8 flex flex-col">
                            <span className="mb-4 font-semibold text-heading">
                                {/* {t('text-follow-us')} */}
                                Follow us
                            </span>
                            <div className="flex items-center justify-start">
                                {contactDetails?.map(
                                    (item, index) => (
                                        <a
                                            key={index}
                                            href={item?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={'px-3 text-muted transition-colors duration-300 focus:outline-none ltr:mr-8 ltr:last:mr-3 rtl:ml-8 rtl:last:ml-0 '}
                                        >
                                            {getIcon({
                                                iconList: socialIcons,
                                                iconName: item?.icon,
                                                className: 'w-4 h-4',
                                            })}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Contact form */}
                    <div className="order-1 mb-8 w-full bg-light p-5 md:order-2 md:mb-0 md:p-8 ltr:md:ml-7 rtl:md:mr-7 ltr:lg:ml-9 rtl:lg:mr-9">
                        <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
                            {/* {t('text-questions-comments')} */}
                            Questions, Comments, Or Concerns?
                        </h1>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Contact;
