// import Header from './header';
import { Outlet } from 'react-router-dom';
import Header from './header';
import MobileNavigation from './mobile-navigation';

export default function GeneralLayout({
  children,
  layout,
}) {
  return (
    <div className="flex min-h-screen flex-col bg-gray-100 transition-colors duration-150">
      <Header layout={layout} isHomePage={false} />
      <Outlet />
      <MobileNavigation />
    </div>
  );
}


