import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import SearchBox from './search-box';
// import { useSearchParams } from 'react-router-dom';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams, ScrollRestoration } from 'react-router-dom';

export default function Search({ label, variant, ...props }) {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    console.log('searchParam=>s', searchParams.get('searchString'))
    const navigate = useNavigate();




    const [searchTerm, updateSearchTerm] = useState('');

    const handleOnChange = (e) => {
        const { value } = e.target;

        updateSearchTerm(value);
    };


    const onSearch = (e) => {
        e.preventDefault();
        if (!searchTerm) return;

        navigate({
            // pathname: "searchString",
            search: createSearchParams({
                searchString: searchTerm
            }).toString()
        },);


        // router.push(
        //     {
        //         pathname,
        //         query: { ...query, text: searchTerm },
        //     },
        //     undefined,
        //     {
        //         scroll: false,
        //     }
        // );
    };

    function clearSearch() {
        updateSearchTerm('');
        // const { pathname, query } = router;
        // const { text, ...rest } = query;
        // if (text) {
        //     router.push(
        //         {
        //             pathname,
        //             query: { ...rest },
        //         },
        //         undefined,
        //         {
        //             scroll: false,
        //         }
        //     );
        // }

        navigate({
            pathname: "/",
            search: createSearchParams({

            }).toString()
        }, { replace: true });
        window.scrollTo(0, 0);
    }


    return (
        <div>
            <SearchBox
                label={label}
                onSubmit={onSearch}
                onClearSearch={clearSearch}
                onChange={handleOnChange}
                value={searchTerm}
                name="search"
                placeholder={t('common:text-search-placeholder')}
                variant={'normal'}
                {...props}
            />

        </div>
    )
}
