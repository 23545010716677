// import type { CategoryPaginator, CategoryQueryOptions } from '@/types';
import axios from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import client from './client';
import { API_ENDPOINTS } from './client/api-endpoints';
// import { mapPaginatorData } from 'framework/utils/data-mappers';
// import { useRouter } from "next/router";

export function useCategories(options) {
  // const { locale } = useRouter();

  const formattedOptions = {
    ...options,
    // language: locale
  }


  // const fetchFromApi = async () => {

  //   console.log('2222222', 2222222)
  //   try {
  //     let response;
  //     await axios.get(API_ENDPOINTS.CATEGORIES).then(
  //       res => {
  //         response = res.data.categories;
  //       }
  //     )

  //     console.log('responseresponseresponse', response)
  //     return response;
  //   }
  //   catch (e) {
  //     console.log('error11111', e)
  //   }

  // }



  // const { isLoading, error, data } = useQuery(["repoData"], fetchFromApi);

  // const { data, isLoading, error } = useQuery(
  //   [API_ENDPOINTS.CATEGORIES, formattedOptions],
  //   ({ queryKey, pageParam }) =>
  //     client.categories.all(Object.assign({}, queryKey[1], pageParam))
  // );


  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [API_ENDPOINTS.CATEGORIES, formattedOptions],
    ({ queryKey, pageParam }) =>
      client.categories.all(Object.assign({}, queryKey[1], pageParam)),
    {
      getNextPageParam: ({ current_page, last_page }) =>
        last_page > current_page && { page: current_page + 1 },
    }
  );


  function handleLoadMore() {
    fetchNextPage();
  }

  return {
    categories: data?.pages?.flatMap((page) => page.data) ?? [],
    // paginatorInfo: Array.isArray(data?.pages)
    //   ? mapPaginatorData(data?.pages[data.pages.length - 1])
    //   : null,
    isLoading,
    error,
    // isFetching,
    // isLoadingMore: isFetchingNextPage,
    // loadMore: handleLoadMore,
    // hasMore: Boolean(hasNextPage),
  };
}
