// import Link from '@/components/ui/link';
// import { Routes } from '@/config/routes';
import { useTranslation } from 'react-i18next';

const headerLinks = [
    { href: '/', icon: null, label: 'nav-menu-shops' },
    // { href: '', icon: null, label: 'nav-menu-offer' },
    { href: '/help', label: 'nav-menu-faq' },
    { href: '/contact', label: 'Contact' },
    { href: '/privacy', label: 'Privacy' },
    { href: '/terms', label: 'Terms & conditions' },
];

const StaticMenu = () => {
    const { t } = useTranslation('common');

    return (
        <>
            {headerLinks.map(({ href, label, icon }) => (
                <li key={`${href}${label}`}>
                    <a

                        href={href}
                        className="flex items-center font-normal text-heading no-underline transition duration-200 hover:text-accent focus:text-accent"
                    >
                        {icon && <span className="ltr:mr-2 rtl:ml-2">{icon}</span>}
                        {t(label)}
                    </a>
                </li>
            ))}
        </>
    );
};

export default StaticMenu;
