import { ChevronLeft } from 'components/icons/chevron-left';
import { ChevronRight } from 'components/icons/chevron-right';
import {
  Swiper,
  SwiperSlide,
  SwiperOptions,
  Navigation,
  Thumbs,
} from 'components/ui/slider';
// import { Image } from '@/components/ui/image';
import { useRef, useState } from 'react';
// import { productPlaceholder } from '@/lib/placeholders';
// import { useIsRTL } from '@/lib/locals';
import classNames from 'classnames';

// product gallery breakpoints
const galleryCarouselBreakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 3,
  },
  800: {
    slidesPerView: 4,
  },
};
const swiperParams = {
  slidesPerView: 1,
  spaceBetween: 0,
};
export const ThumbsCarousel = ({
  gallery,
  hideThumbs = false,
  aspectRatio = 'square',
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const { isRTL } = useIsRTL();
  const isRTL = true;
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div>
      <div className="relative">
        <Swiper
          id="productGallery"
          modules={[Navigation, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          navigation={{
            prevEl: prevRef.current, // Assert non-null
            nextEl: nextRef.current, // Assert non-null
          }}
          {...swiperParams}
        >
          {gallery?.map((item, key) => (
            <SwiperSlide
              key={`product-gallery-${key}`}
              className="flex items-center justify-center selection:bg-transparent"
            >
              <img
                src={item}
                alt={`Product gallery ${key}`}
                width={aspectRatio === 'square' ? 450 : 420}
                height={aspectRatio === 'square' ? 450 : 560}
                // layout="responsive"
                className="ltr:ml-auto rtl:mr-auto"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div
          ref={prevRef}
          className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5"
        >
          {isRTL ? (
            <ChevronRight className="h-4 w-4" />
          ) : (
            <ChevronLeft className="h-4 w-4" />
          )}
        </div>
        <div
          ref={nextRef}
          className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5"
        >
          {isRTL ? (
            <ChevronLeft className="h-4 w-4" />
          ) : (
            <ChevronRight className="h-4 w-4" />
          )}
        </div> */}
      </div>
      {/* End of product main slider */}

      <div
        className={classNames(
          'relative mx-auto mt-5 max-w-md lg:mt-8 lg:pb-2',
          {
            hidden: hideThumbs,
          }
        )}
      >
        <Swiper
          id="productGalleryThumbs"
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          watchSlidesProgress={true}
          freeMode={true}
          observer={true}
          observeParents={true}
          breakpoints={galleryCarouselBreakpoints}
        >
          {gallery?.map((item, key) => (
            <SwiperSlide
              key={`product-thumb-gallery-${key}`}
              className="flex cursor-pointer items-center justify-center overflow-hidden rounded border border-border-200 border-opacity-75 hover:opacity-75"
            >
              <img
                src={item}
                alt={`Product thumb gallery ${key}`}
                width={80}
                height={80}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
