import { Swiper, SwiperSlide, Navigation, Autoplay } from 'components/ui/slider';
// import { productPlaceholder } from 'lib/placeholders';
// import { useIsRTL } from '@/lib/locals';
import { ArrowNext, ArrowPrev } from 'components/icons';
import { useTranslation } from 'react-i18next';


const BannerShort = ({ banners }) => {
  const { t } = useTranslation('common');
  const isRTL = true
  return (
    <div className="relative">
      <div className="-z-1 overflow-hidden">
        <div className="relative">
          <Swiper
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            id="banner"
            loop={true}
            modules={[Navigation, Autoplay]}
            resizeObserver={true}
            allowTouchMove={true}
            slidesPerView={1}
            navigation={{
              nextEl: '.next',
              prevEl: '.prev',
            }}
          >
            {banners?.map((banner, idx) => (
              <SwiperSlide key={idx}>
                <div className="relative h-full max-h-[240px] w-full md:max-h-[450px]">
                  <img
                    className="h-full w-full"
                    src={banner}
                    alt={banner}
                    layout="responsive"
                    width={1503}
                    height={450}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div
            className="prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-200 transition-all duration-200 ltr:left-4 rtl:right-4 md:-mt-5 ltr:md:left-5 rtl:md:right-5"
            role="button"
          >
            <span className="sr-only">{t('text-previous')}</span>

            {isRTL ? (
              <ArrowNext width={18} height={18} />
            ) : (
              <ArrowPrev width={18} height={18} />
            )}
          </div>
          <div
            className="next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-200 transition-all duration-200 ltr:right-4 rtl:left-4 md:-mt-5 ltr:md:right-5 rtl:md:left-5"
            role="button"
          >
            <span className="sr-only">{t('text-next')}</span>
            {isRTL ? (
              <ArrowPrev width={18} height={18} />
            ) : (
              <ArrowNext width={18} height={18} />
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BannerShort;
