import ContactGrid from 'components/checkout/contact/contact-grid';
import Button from 'components/ui/button';
import { Form } from 'components/ui/form/form';
import Input from 'components/ui/form/input';
import TextArea from 'components/ui/form/text-area';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useCart } from 'store/quick-cart/cart.context';
import { billingAddressAtom, shippingAddressAtom } from 'store/checkout';
import { useNavigate } from 'react-router';
import { useCheckout } from 'framework/rest/checkout';


const checkoutFormSchema = yup.object().shape({
  customer_name: yup.string().required('Customer name required'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email required'),
  phone_number: yup.string().required('Phone number required'),
  address: yup.string().required('Address required'),
  company_name: yup.string().required('Company name required'),
  tax_number: yup.string().required('Tax number required'),
  post_code: yup.string().required('Post code required'),


});


export default function CheckoutPage() {
  const navigate = useNavigate();
  const { items, resetCart, resetCheckout } = useCart();

  console.log('items :>> ', items);
  const { t } = useTranslation();

  const { mutate, isLoading } = useCheckout();

  function onSubmit(values) {


    values.items = items

    mutate(values);
    resetCart()
    navigate('/')
  }


  return (
    <>
      <div className="px-4 py-8 bg-gray-100 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
        <div className="flex flex-col items-center w-full max-w-3xl m-auto rtl:space-x-reverse lg:flex-row lg:items-center lg:space-x-8">
          <div className="w-full space-y-6 lg:max-w-2xl">

            <Form onSubmit={onSubmit} validationSchema={checkoutFormSchema}>
              {({ register, formState: { errors } }) => (
                <>

                  <Input
                    label={'Name'}
                    {...register('customer_name')}
                    variant="outline"
                    error={t(errors.customer_name?.message)}
                  />
                  <br />

                  <Input
                    label={'Email'}
                    type="email"
                    {...register('email')}
                    variant="outline"
                    error={t(errors.email?.message)}
                  />

                  <br />

                  <Input
                    label={'Phone'}
                    {...register('phone_number')}
                    variant="outline"
                    error={t(errors.phone_number?.message)}
                  />
                  <br />


                  <Input
                    label={'Firma'}
                    {...register('company_name')}
                    variant="outline"
                    error={t(errors.company_name?.message)}
                  />
                  <br />

                  <Input
                    label={'MWST'}
                    {...register('tax_number')}
                    variant="outline"
                    error={t(errors.tax_number?.message)}
                  />
                  <br />



                  <Input
                    label={'PLZ'}
                    {...register('post_code')}
                    variant="outline"
                    error={t(errors.post_code?.message)}
                  />
                  <br />

                  <TextArea
                    label={'Address'}
                    {...register('address')}
                    variant="outline"
                    className="my-6"
                    error={t(errors.address?.message)}
                  />
                  <br />



                  <Button loading={isLoading} disabled={isLoading}>
                    Submit
                  </Button>


                </>
              )}

            </Form>
          </div>

        </div>
      </div>
    </>
  );
}
