import React from 'react'
import Button from '../components/ui/button'
import { useTranslation } from 'react-i18next';

export default function NotFound() {
    const { t } = useTranslation('common');
    return (
        <div className="grid min-h-screen place-items-center p-4 sm:p-8">
            <div className="text-center">
                <p className="2xl: mb-4 text-sm uppercase tracking-widest text-body-dark sm:mb-5">
                    {t('404-heading')}
                </p>
                <h1 className="mb-5 text-2xl font-bold leading-normal text-bolder sm:text-3xl">
                    {t('404-sub-heading')}
                </h1>
                <div className="mb-11">
                    <img alt="Not found" src="https://pickbazar-react.vercel.app/_next/static/media/no-result.b574bcc9.svg" />
                </div>
                <Button
                    href={'#'}
                >
                    {t('404-back-home')}
                </Button>
            </div>
        </div>
    )
}
