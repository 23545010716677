import React from 'react'
import StickySidebarBoxedCategories from './sticky-sidebar-boxed-categories'
import { useCategories } from 'framework/rest/category';
import CategoriesLoader from 'components/ui/loaders/categories-loader';
import ErrorMessage from 'components/ui/error-message';

export default function Categories() {

    const { categories, isLoading, error } = useCategories();

    if (error) return <ErrorMessage message={error.message} />;

    if (isLoading) {
        return (
            <div className="hidden xl:block">
                <div className="mt-8 w-72 px-2">
                    <CategoriesLoader />
                </div>
            </div>
        );
    }

    return (
        categories && <StickySidebarBoxedCategories categories={categories} notFound={!categories.length} />
    )
}
