import React from 'react'
import StaticMenu from './menu/static-menu'
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Logo from '../ui/logo';
import Search from '../ui/search/search';
import { useAtom } from 'jotai';
import { displayMobileHeaderSearchAtom } from 'store/display-mobile-header-search-atom';


export default function Header({ isHomePage }) {
    const { t } = useTranslation('common');
    const isMultilangEnable = false


    const [displayMobileHeaderSearch] = useAtom(displayMobileHeaderSearchAtom);
    return (
        <div>
            <header
                className={cn('site-header-with-search h-14 md:h-16 lg:h-22', {
                    'lg:!h-auto': '',
                })}
            >
                <div
                    className={cn(
                        'fixed z-50 flex h-20 w-full transform-gpu items-center justify-between border-b border-border-200 bg-light px-4 py-5 shadow-sm transition-transform duration-300 md:h-16 lg:h-22 lg:px-8',
                        {
                            'lg:absolute lg:border-0 lg:bg-transparent lg:shadow-none':
                                '',
                        }
                    )}
                >
                    <div className="flex w-full items-center justify-center lg:w-auto">
                        <Logo
                            className={`${!isMultilangEnable ? 'mx-auto lg:mx-0' : 'ltr:ml-0 rtl:mr-0'
                                } `}
                        />

                        {/* {isMultilangEnable ? (
                            <div className="ltr:ml-auto rtl:mr-auto lg:hidden">
                                <LanguageSwitcher />
                            </div>
                        ) : (
                            ''
                        )} */}

                        {/* <div className="hidden ltr:ml-10 ltr:mr-auto rtl:mr-10 rtl:ml-auto xl:block">
                            <GroupsDropdownMenu />
                        </div> */}
                    </div>
                    {isHomePage ? (
                        <>
                            <div className="mx-auto hidden w-full overflow-hidden px-10 lg:block xl:w-11/12 2xl:w-10/12">
                                <Search label={t('text-search-label')} variant="minimal" />
                            </div>

                            {displayMobileHeaderSearch && (
                                <div className="absolute top-0 block h-full w-full bg-light px-5 pt-1.5 ltr:left-0 rtl:right-0 md:pt-2 lg:hidden">
                                    <Search label={t('text-search-label')} variant="minimal" />
                                </div>
                            )}
                        </>
                    ) : null}
                    <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
                        <StaticMenu />
                        {/* <div className="flex items-center space-x-4 rtl:space-x-reverse">
                            <a
                                href={`${process.env.NEXT_PUBLIC_ADMIN_URL}/register`}
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex h-9 shrink-0 items-center justify-center rounded border border-transparent bg-accent px-3 py-0 text-sm font-semibold leading-none text-light outline-none transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700"
                            >
                                {t('text-become-seller')}
                            </a>
                            <li>{isAuthorize ? <AuthorizedMenu /> : <JoinButton />}</li>
                        </div> */}
                        {/* {isMultilangEnable ? (
                            <div className="ms-auto lg:me-5 xl:me-8 2xl:me-10 hidden flex-shrink-0 lg:block">
                                <LanguageSwitcher />
                            </div>
                        ) : (
                            ''
                        )} */}
                    </ul>
                </div>
            </header>
        </div>
    )
}
