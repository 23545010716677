import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import React from 'react'
import Header from './header'
import { useTranslation } from 'react-i18next';
import MobileNavigation from './mobile-navigation';
import { displayMobileHeaderSearchAtom } from 'store/display-mobile-header-search-atom';
import { SearchIcon } from 'components/icons/search-icon';
import { Outlet, Route, Routes } from 'react-router-dom';
import Contact from 'contact';
import PromotionSliders from 'components/promotions/promotions';
import BannerShort from 'components/banners/banner-short';
import CartCounterButton from 'components/cart/cart-counter-button';

export default function HomeLayout({ children }) {

    const { t } = useTranslation('common');

    const [, setDisplayMobileHeaderSearch] = useAtom(
        displayMobileHeaderSearchAtom
    );

    return (
        <div>
            <div className="flex flex-col min-h-screen transition-colors duration-150 bg-gray-100">

                <Header isHomePage={true} />


                <div className="min-h-screensw">
                    <Outlet />
                </div>
                




                <MobileNavigation>
                    <motion.button
                        whileTap={{ scale: 0.88 }}
                        onClick={() => setDisplayMobileHeaderSearch((prev) => !prev)}
                        className="flex items-center justify-center h-full p-2 focus:outline-none focus:text-accent"
                    >
                        <span className="sr-only">{t('text-search')}</span>
                        <SearchIcon width="17.05" height="18" />
                    </motion.button>

                </MobileNavigation>
            </div>
        </div>
    )
}
