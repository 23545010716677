export const API_ENDPOINTS = {
  PRODUCTS: '/product',
  // PRODUCTS_POPULAR: '/popular-products',
  // PRODUCTS_REVIEWS: '/reviews',
  // PRODUCTS_REVIEWS_ABUSE_REPORT: '/abusive_reports',
  // PRODUCTS_QUESTIONS: '/questions',
  // FEEDBACK: '/feedbacks',
  CATEGORIES: '/category',
  // TYPES: '/types',
  // TAGS: '/tags',
  // SHOPS: '/shops',
  // AUTHORS: '/authors',
  // AUTHORS_TOP: '/top-authors',
  // MANUFACTURERS: '/manufacturers',
  // MANUFACTURERS_TOP: '/top-manufacturers',
  // COUPONS: '/coupons',
  // COUPONS_VERIFY: '/coupons/verify',
  // ORDERS: '/orders',
  // ORDERS_STATUS: '/order-status',
  // ORDERS_REFUNDS: '/refunds',
  // ORDERS_CHECKOUT_VERIFY: '/orders/checkout/verify',
  // ORDERS_DOWNLOADS: '/downloads',
  // GENERATE_DOWNLOADABLE_PRODUCT_LINK: '/downloads/digital_file',
  // USERS: '/users',
  // USERS_ADDRESS: '/address',
  // USERS_ME: '/me',
  // USERS_LOGIN: '/token',
  // USERS_REGISTER: '/register',
  // USERS_FORGOT_PASSWORD: '/forget-password',
  // USERS_VERIFY_FORGOT_PASSWORD_TOKEN: '/verify-forget-password-token',
  // USERS_RESET_PASSWORD: '/reset-password',
  // USERS_CHANGE_PASSWORD: '/change-password',
  // USERS_LOGOUT: '/logout',
  // USERS_SUBSCRIBE_TO_NEWSLETTER: '/subscribe-to-newsletter',
  // USERS_CONTACT_US: '/contact-us',
  SEND_EMAIL: '/sendEmail',
  SEND_ORDER: '/orders-create',
  // USERS_WISHLIST: '/my-wishlists',
  // WISHLIST: '/wishlists',
  // USERS_WISHLIST_TOGGLE: '/wishlists/toggle',
  // SOCIAL_LOGIN: '/social-login-token',
  // SEND_OTP_CODE: '/send-otp-code',
  // VERIFY_OTP_CODE: '/verify-otp-code',
  // OTP_LOGIN: '/otp-login',
  // UPDATE_CONTACT: '/update-contact',
  // SETTINGS: '/settings',
  // UPLOADS: '/attachments',
  // MY_QUESTIONS: '/my-questions',
  // MY_REPORTS: '/my-reports',
};
